import { rgba } from 'polished';

const styles = theme => ({
  root: {
  },

  seeMore: {
    marginTop: 20
  },

  dataContainer:{
    display: 'flex',
    flexDirection: 'column',
    padding: ["4vh","2vw"],
    transition: ['background 200ms ease-out','color 200ms ease-out'].join(','),
    '&:hover, &:focus': {
    backgroundColor: '#27e1fa0d'}
  },

  videoContainer: {
    display: 'flex',
    margin: [0, 'auto', '2em', 'auto'],
    padding: 1,
    width: '100%',
    height: '100%',
    backgroundColor: rgba(theme.color.tertiary.main, 0.5),
    clipPath: 'polygon(100% 0, 0 0, 0 100%, 100% 100%, 100% 70%, 95% 65%, 95% 35%, 100% 30%)'
  },

  video: {
    flex:1,
    position: 'relative',
    objectFit: 'cover',
    top: 0,
    left: 0,
    width:'100%',
    height:'100%',
    clipPath: 'polygon(100% 0, 0 0, 0 100%, 100% 100%, 100% 70%, 95% 65%, 95% 35%, 100% 30%)'
    }, 


  data: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    color: theme.color.text.main,
    wordBreak: 'break-word'
  },

  '@media screen and (min-width: 480px)': {
    dataContainer: {
      flexDirection: 'row',
      padding: 20
    },

    videoContainer: {
      width: 200,
      height: 200,
      marginRight: '2em',
      clipPath: 'polygon(100% 0, 0 0, 0 100%, 100% 100%, 100% 70%, 95% 65%, 95% 35%, 100% 30%)'
    }
  },    
  

  '@media screen and (min-width: 768px)': {
    dataContainer: {
      padding: 35
    }
  },

  skills: {
      display: 'grid',
      gridTemplateColumns: 'repeat( auto-fit, minmax(22vh, 1fr) )'

  },

  contactVideo: {
    width: "100%",
    height: "35vh",
    flex: "1",
  },

  contactBtn: {

    width: "100%",
    textAlign: "start",
    fontSize: ".7rem",
    letterSpacing: "2px",
  },

  contactClipPath: {
    clipPath: "polygon(100% 0, 2% 0, 0 2%, 0 25%, 2% 27%, 2% 73%, 0 75%, 0 95%, 5% 100%, 100% 100%, 100% 76%, 99% 75%, 99% 26%, 100% 25%)",
  },

  detailsClipPath: {
    clipPath: "polygon(99.5% .5%, 85% 1%, 85% 0, 15% 0, 15% .5%, .5% .5%, .5% 15%, 0 15%, 0 85%, .5% 85%, .5% 99.5%, 15% 99.5%, 15% 100%, 85% 100%, 85% 99.5%, 99.5% 99.5%, 99.5% 85%, 100% 85%, 100% 15%, 99.5% 15%)"
  },

  noClipPath: {
    clipPath: "none",
    backgroundColor: "#bef8ff",
    padding: 0 
  },


  noBackground: {
    backgroundColor: "transparent"
  },

  qrImg: {

    height: "100%",
    width: "auto"

  },

  downloadBtn: {

    marginBlockStart: "3vh"
  }
  


});

export { styles };
