import { margin, rgba } from 'polished';
import iconURL from '../../images/favicon.png';

const styles = theme => ({
  root: {
    position: 'relative',
    display: 'block',
    margin: 0
  },
  lineTop: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    borderTop: '1px solid ' + rgba(theme.color.primary.dark, 0.25),
    width: ({ energy }) => energy.animate ? '0%' : '100%'
  },
  link: {
    display: 'flex',
    flexDirection: 'column',
    padding: [40, 20],
    transition: [
      'background 200ms ease-out',
      'color 200ms ease-out'
    ].join(','),

    '&:hover, &:focus': {
      backgroundColor: ({ energy }) => energy.entered && rgba(theme.color.secondary.main, 0.05)
    }
  },
  media: {
    display: 'flex',
    margin: [0, 'auto', '2em', 'auto'],
    padding: 1,
    width: '100%',
    height: '75vw',
    backgroundColor: "#bef8ff",
    filter: 'drop-shadow(0px 0px 3px #87f7fc)'

  },
  image: {
    flex: 1,
    // border: '1px solid ' + rgba(theme.color.secondary.dark, 0.125),
    // backgroundColor: '#000',
    backgroundImage: `url(${iconURL})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    clipPath: 'polygon(100% 0, 70% 0, 65% 5%, 35% 5%, 30% 0, 0 0, 0% 100%, 100% 100%);',
  },


  info: {
    flex: 1,
    color: theme.color.text.main,
    wordBreak: 'break-word'
  },
  title: {
    fontSize: '.8rem'
  },


  message: {
    margin: 0
  },

  '@media screen and (min-width: 480px)': {
    link: {
      flexDirection: 'row',
      padding: 20
    },

    title: {
      fontSize: '1rem'
    },

    media: {
      width: 200,
      height: 200,
      marginRight: '2em',
      
      
    }

  },

  '@media screen and (min-width: 768px)': {
    link: {
      padding: 40
    }
  }
});

export { styles };
