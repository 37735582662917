import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '../tools/withStyles';
import { Link } from '../components/Link';
import { Main } from '../components/Main';
import { Post } from '../components/Post';
import { Secuence } from '../components/Secuence';
import { Text } from '../components/Text';
import { Fader } from '../components/Fader';
import profileGif from '../images/profilepic.mp4';
import {styles } from '../layouts/pages/pages.styles';




class details extends React.Component {
  static propTypes = {
    classes: PropTypes.object
  };

  getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
};

  render () {
    const { classes } = this.props;

    return (
      <Main className={classes.root}>
        <Secuence stagger>
          <header>
            <h1><Text>Biographical Information</Text></h1>
          </header>
          
            <Fader className={classes.dataContainer}>
             
                <div className={`${classes.videoContainer} ${classes.noClipPath}`}>
 
                  <video className={ `${classes.video} ${classes.detailsClipPath}`} preload="metadata" playsInline  muted  autoPlay loop preload="metadata" >
                    <source src={profileGif} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>

                </div>

                <div className={classes.data}>
                <h2><Text>Full Biography Mode</Text></h2>
                <Fader>
                <p>
                    Name:  Muhammad Ramadan  <br />
                    Occupation:  Front-end Developer <br />
                    Status:  Alive (508) <br />
                    Age: {this.getAge("1991/08/20")} <br />
                    Species:  Human (Cyborg) <br />
                    Place of origin: Earth (Dimension C-137) <br />
                    Marital status:  Single <br  />
                    Home planet: Earth (Replacement Dimension) <br />
                    Education:  B.A. Degree in Philosophy, 2013. 
                </p>

                <hr />
                
                  <h2><Text>Powers / Skills:</Text> </h2>  
                  
                  <div className={classes.skills}>
                    <div>
                    <h3><Text>Front-End</Text></h3>
                    <p>
                    HTML5 <br />
                    CSS3   <br />
                    JAVASCRIPT <br />
                    SASS <br />
                    STYLED COMPONENTS <br />
                    REACT <br />
                    </p>
                    </div>

                    <div>
                    <h3><Text>GRAPHIC &amp; DESIGN</Text></h3>
                    <p>
                    Photoshop <br />
                    Adobe Illustrator <br />
                    Figma <br />
                    Blender
                    </p>
                    </div>

                    <div>
                    <h3><Text>OTHER</Text></h3>
                    <p>
                    LINUX <br />
                    GITHUB <br />
                    GCP <br />
                    Windows Server <br />
                    </p>
                    </div>


                </div>    

                <hr />
                <h2><Text>Career Summary:</Text> </h2> 

                <p>
                   2015 – As Technical Support Representative at TE-Data (EGY). <br />
                    2017 – As IT Helpdesk Support at TE-Data (EGY). <br />
                    2018 – As Airport Customer Services Agent at DUBAI AIRPORTS (UAE). <br />
                    2021 – As Freelamcer Front-end Developer. <br/>
                    2022 - As Full-Stack Developer at Coldwell Banker UAE.
                </p>

                <hr />
                
                <p>
                In the vibrant heart of the digital universe, I am a Full Stack Web Developer with a passion for AI, programming, and cybersecurity. My world is where code meets creativity, where every line is a step towards pioneering new tech horizons. Amidst the neon echoes of cyberspace, I craft digital experiences that transcend the ordinary. Join me in this quest to shape the future, one pixel at a time.
                </p>


              </Fader>

                </div>

            </Fader>

            
          <p className={classes.seeMore}>
          <Text>See more at</Text>
            {' '}
            <Link href='https://www.linkedin.com/in/mu-ramadan/' target='LinkedIn'><Text>LinkedIn</Text></Link>
          </p>
        </Secuence>
      </Main>
    );
  }
}

export default withStyles(styles)(details);
